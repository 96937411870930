/*Dashboard*/
header.ant-layout-header {
    z-index: 10 !important;
}

/*Form*/

.dashboardCalendar td.ant-picker-cell {
    height: 100px;
}
.dashboardCalendar div.ant-picker-cell-inner.ant-picker-calendar-date {
    height: 100% !important;
}
.dashboardCalendar .ant-picker-calendar-date-content {
    overflow-y: visible !important;
    overflow-x: auto;
    height: calc(100% - 24px) !important;
}

.dashboardCalendar .scheduleItem {
    white-space: nowrap;
    font-size: small;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #ccc;
    width: fit-content;
    height: 20px;
    margin-bottom: 5px;
    padding-right: 5px;
}

.dashboardCalendar .scheduleItem:hover {
    background-color: #f2f2f2;
}

#currencyInput {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
}

#currencyInput:focus {
    border-color: #1677ff;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    outline: 0;
}

.ant-picker-time-panel-cell.ant-picker-time-panel-cell-disabled {
    display: none;
}

.quill .ql-toolbar.ql-snow {
    border-radius: 6px 6px 0px 0px;
    border-color: #d9d9d9;
}

.quill .ql-container.ql-snow {
    border-radius: 0px 0px 6px 6px;
    border-color: #d9d9d9;
}

.textEditorDescription .quill .ql-container.ql-snow {
    border-radius: 6px;
    border-color: #d9d9d9;
    max-height: 73px;
    overflow-y: auto;
}

/* .textEditorDescription .quill .ql-container.ql-snow ul,
.textEditorDescription .quill .ql-container.ql-snow ol {
    padding-left: 0px;
}
.textEditorDescription .quill .ql-container.ql-snow ul li::before {
    margin-left: -30px;
}

.textEditorDescription .quill .ql-container.ql-snow ol li::before {
    margin-left: -22px;
} */

.ant-color-picker-trigger:hover {
    border-color: #d9d9d9 !important;
}

/*Calendar*/

.fc-dayGridDay-button.fc-button.fc-button-primary,
.fc-dayGridMonth-button.fc-button.fc-button-primary,
.fc-timeGridWeek-button.fc-button.fc-button-primary,
.fc-listWeek-button.fc-button.fc-button-primary {
    color: rgba(0, 0, 0, 0.88);
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 30px;
}

/* .fc-dayGridMonth-button.fc-button.fc-button-primary:focus-visible,
.fc-timeGridWeek-button.fc-button.fc-button-primary:focus-visible,
.fc-listWeek-button.fc-button.fc-button-primary:focus-visible {
    outline: 4px solid #91caff;
    outline-offset: 1px;
    transition: outline-offset 0s, outline 0s;
} */

.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active,
.fc-dayGridDay-button.fc-button.fc-button-primary.fc-button-active,
.fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active,
.fc-listWeek-button.fc-button.fc-button-primary.fc-button-active {
    z-index: 5;
    background: #ffffff;
    border-color: #1677ff;
    color: #1677ff;
}

.fc-dayGridMonth-button.fc-button.fc-button-primary:hover,
.fc-dayGridDay-button.fc-button.fc-button-primary:hover,
.fc-timeGridWeek-button.fc-button.fc-button-primary:hover,
.fc-listWeek-button.fc-button.fc-button-primary:hover {
    background: #ffffff;
    color: #1677ff;
}

.fc-dayGridMonth-button.fc-button.fc-button-primary:focus,
.fc-dayGridDay-button.fc-button.fc-button-primary:focus,
.fc-timeGridWeek-button.fc-button.fc-button-primary:focus,
.fc-listWeek-button.fc-button.fc-button-primary:focus {
    background: #ffffff;
    border-color: #1677ff;
    color: #1677ff;
}

.fc .fc-button-primary:focus,
.fc .fc-button:focus {
    box-shadow: none !important;
}

.fc .fc-button .fc-icon {
    font-size: 1.3em;
}
.fc-prev-button.fc-button.fc-button-primary,
.fc-next-button.fc-button.fc-button-primary {
    color: rgba(0, 0, 0, 0.88);
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    height: 32px;
    display: flex;
    font-size: 14px;
    line-height: 30px;
}
.fc-prev-button.fc-button.fc-button-primary:hover,
.fc-next-button.fc-button.fc-button-primary:hover {
    background: #ffffff;
    color: #1677ff;
}
.fc-prev-button.fc-button.fc-button-primary:active,
.fc-next-button.fc-button.fc-button-primary:active {
    z-index: 5;
    background: #ffffff;
    border-color: #1677ff;
    color: #1677ff;
}

.fc-toolbar-title {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: normal;
    font-size: 1.5em !important;
}

.fc-today-button.fc-button.fc-button-primary {
    color: #fff;
    background-color: #1677ff;
    font-size: 14px;
    height: 32px;
    padding: 4px 15px;
    border: none;
    border-radius: 6px;
}

.fc-today-button.fc-button.fc-button-primary[disabled] {
    cursor: not-allowed;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.04);
    box-shadow: none;
    border: 1px solid;
}

.fc-today-button.fc-button.fc-button-primary[disabled]:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.fc-today-button.fc-button.fc-button-primary:hover {
    background-color: #4096ff;
}

.fc-today-button.fc-button.fc-button-primary:active {
    background-color: #0958d9;
}

.fc-dayGridMonth-view table {
    border-collapse: separate !important;
    border-spacing: 4px 0px;
    border-radius: 50px;
    border: none !important;
}
.fc-view .fc-scrollgrid-sync-inner a {
    color: rgba(0, 0, 0, 0.88);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: normal;
}

.fc-dayGridMonth-view td {
    border: none;
    border-top: 2px solid rgba(5, 5, 5, 0.06);
}

.fc-dayGridMonth-view td.fc-day.fc-day-today {
    border-top: 2px solid #1677ff;
}

.fc-dayGridMonth-view th {
    border: none;
    text-align: end;
    padding-right: 4px;
}

.fc-daygrid-day-frame {
    padding-inline: 4px;
}

.fc-view .fc-day.fc-day-today {
    background-color: #e6f4ff !important;
}
.fc-view .fc-day.fc-day-today .fc-scrollgrid-sync-inner a {
    color: #1677ff !important;
}

.fc-list-day-cushion.fc-cell-shaded a {
    color: rgba(0, 0, 0, 0.88);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: normal;
}

.fc-daygrid-event-harness {
    margin-bottom: 5px;
}

.fc-daygrid-day-events a.fc-event {
    border: 1px solid #ccc;
    background-color: #fff;
}

.fc-daygrid-day-events a.fc-event:hover {
    background-color: #f2f2f2;
}

.fc-daygrid-day-events a.fc-event:focus {
    box-shadow: none;
}

.fc-daygrid-day-events a.fc-event:focus::after {
    background-color: transparent;
}

.fc-timeGridWeek-button.fc-button fc-button-primary {
    position: relative;
}
.testeabc {
    position: absolute;
    right: 16px;
    top: 20px;
    z-index: 500;
}

/*Modal Signature*/
.modalTitle {
    font-size: 16px;
}

button.ant-modal-close {
    display: none !important;
}

.modalDocumentSign button.ant-modal-close {
    display: block !important;
}

.modalDocumentSign {
    height: 100vh;
    padding: 20px 0px;
}

.modalDocumentSign .ant-modal-content {
    height: calc(100% - 20px);
}

.modalDocumentSign .ant-modal-content .ant-modal-body {
    height: calc(100% - 32px);
}

.modalDocumentSign .ant-modal-content .ant-modal-body iframe {
    height: 100% !important;
}

/* CALENDAR */
/* Adicionar transições suaves */
.ant-card {
    transition: all 0.3s ease;
    cursor: pointer;
}

.ant-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Container principal */
.timeline-container {
    position: relative;
    height: 1440px; /* 24 horas * 60 minutos = 1440px (1px por minuto) */
}

/* Cards de agendamento */
.appointment-card {
    position: absolute;
    width: 100%;
    transition: all 0.3s;
}

.patientRecord .ant-card-body {
    padding-right: 0px !important;
}

.patientRecord .scrollbar-container {
    padding-right: 24px;
    max-height: calc(100vh - 248px);
}
/* Layout responsivo */
@media (max-width: 768px) {
    .ant-col-16,
    .ant-col-8 {
        width: 100% !important;
        max-width: 100% !important;
    }

    .time-marker {
        display: none;
    }
}

/*Mobile*/
@media only screen and (max-width: 500px) {
    /*  li.ant-menu-submenu .ant-menu-submenu-title:active {
        background-color: red;
    }

    li.ant-menu-submenu .ant-menu-submenu-title:active {
        background-color: red;
    } */

    /* Login */
    #rightSideAuth {
        width: 100% !important;
        max-width: 100% !important;
        flex: none !important;
    }
    #leftSideAuth {
        display: none;
    }

    /* Menu */
    .ant-layout-header li.ant-menu-item,
    li.ant-menu-submenu {
        flex: 1;
        text-align: center;
    }
    .ant-layout-header li.ant-menu-item span,
    .ant-layout-header li.ant-menu-submenu span {
        margin-left: 0px !important;
    }

    /* Calendar */
    .fc-header-toolbar.fc-toolbar {
        display: grid;
        grid-template-areas:
            "div2 div2"
            "div1 div3";
    }

    .fc-header-toolbar.fc-toolbar > :nth-child(1) {
        grid-area: div1;
    }
    .fc-header-toolbar.fc-toolbar > :nth-child(2) {
        grid-area: div2;
        text-align: center;
        margin-bottom: 10px;
    }
    .fc-header-toolbar.fc-toolbar > :nth-child(3) {
        grid-area: div3;
    }
    /*  .fc-toolbar-title {
        font-size: 0.5em !important;
    } */

    /* Tables */
    .table-header-button-search {
        flex-direction: column-reverse;
    }

    .modal-header-title-button button {
        width: 100%;
    }

    .modal-header-title-button .modalTitle {
        display: none;
    }
}
